import { Join } from "../Common/Join";

export function Banner() {
    return (
        <div id="home-banner" className="flex items-center mb-4 sm:mb-0 justify-center sm:justify-start text-center sm:text-left h-[395px] sm:h-[512px] mx-[-20px] bg-white sm:bg-[#000]">
            <div className="flex flex-col justify-center items-center sm:block sm:ml-[90px] w-[354px] sm:w-[571px]">
                <span className="mt-3 mb-2 text-2xl sm:text-[34px] h-[46px] sm:h-auto font-semibold leading-[46px] text-neutral-900 sm:text-neutral-100">Lock ‘n Load Your Inbox<span className="hidden sm:inline">...</span></span>
                <p className="w-[320px] sm:w-auto mb-6 text-sm leading-6 sm:text-base sm:leading-5 sm:tracking-[1.28px] text-neutral-900 sm:text-neutral-100">Rare finds, VIP giveaways, and electrifying video content.<span className="hidden sm:inline">..</span> <br className="hidden sm:block" />All delivered weekly, directly to your inbox, for free.</p>
                <Join w="290px" />
                <p className="mt-8 sm:mt-2 mb-6 leading-5 tracking-[1.28px] text-neutral-900 sm:text-neutral-100">Your adventure starts now</p>
            </div>
        </div>
    )
}
