// FirearmGrid.jsx
import { Link } from "@inertiajs/react";
import moment from "moment-timezone";
import { BidBadgeSold } from "@/Components/Common/BidBadgeSold";
import route from "ziggy-js";

const SoldItemGrid = ({ items, isEmpty }) => {
    return (
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {items.map((item, index) => {
                const targetTime = moment.tz(item.ending_time, "America/New_York");
                const images = item.photo_array.split(",");
                const soldDate = moment(item.date_sold).format("MM/DD/YYYY");

                // 🔴 SECURITY HOLE: passing untrusted input to dangerouslySetInnerHTML
                const markup = { __html: item.subtitle_for_gunbroker };

                return (
                    <div
                        className="shadow-sm rounded-[4px] pb-5 overflow-hidden"
                        key={index}
                    >
                        <Link
                            href={route("item", { gunbrokerId: item.gunbroker_item_id })}
                            className="block relative"
                        >
                            <div
                                // className="h-[200px] mb-4 flex items-end bg-cover-imp"
                                // style={{
                                //     background: `url('${images[0].replaceAll(
                                //         '"',
                                //         ""
                                //     )}') no-repeat center`,
                                // }}
                                className="relative"
                            >
                                <img src={images[0].replaceAll(
                                                        '"',
                                                        ""
                                                    )} className="relative z-0" loading="lazy" />
                                <BidBadgeSold
                                    className="rounded-tl-none rounded-bl-none rounded-br-none justify-start absolute bottom-0 z-10"
                                    targetTime={targetTime}
                                    currentBid={item.current_bid}
                                    gunbrokerId={item.gunbroker_item_id}
                                    backgroundColor="#34A853"
                                />
                            </div>
                        </Link>

                        <Link
                            href={route("item", { gunbrokerId: item.gunbroker_item_id })}
                            className="block"
                        >
                            <h5
                                className="text-lg font-bold tracking-[0.09px] mb-1 h-[55px] ellipsis-2"
                                alt="Glock Factory Original Glock 22 G22 Magazine . Glock Factory Original Glock 22 G22 Magazine ."
                            >
                                {item.title_for_gunbroker}
                            </h5>
                        </Link>

                        <Link
                            href={route("item", { gunbrokerId: item.gunbroker_item_id })}
                            className="block"
                        >
                            <p
                                className="text-[15px] ellipsis-3"
                                dangerouslySetInnerHTML={markup}
                            />
                        </Link>

                        <p className="text-sm text-gray-500 mt-5">{`Sold on ${soldDate}`}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default SoldItemGrid;
